import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VCardText,[_c('DateTimePicker',{on:{"getDateFrom":function($event){_vm.dateFrom = $event},"getDateTo":function($event){_vm.dateTo = $event}}}),(!_vm.$checkRole('ADVERTISERS') && !_vm.$checkRole('ADVERTISERS_USERS') && !_vm.$checkRole('PROVIDERS'))?_c(VRow,[_c(VCol,{attrs:{"cols":"6","xl":"6","md":"6","sm":"12"}},[_c(VAutocomplete,{attrs:{"items":_vm.advertisers,"item-text":"name","item-value":"id","label":_vm.$t('Advertiser'),"disabled":_vm.loadingFirst,"clearable":"","hide-details":""},model:{value:(_vm.advertiserId),callback:function ($$v) {_vm.advertiserId=$$v},expression:"advertiserId"}})],1),_c(VCol,{attrs:{"cols":"1","xl":"1","md":"1","m":"1"}},[(_vm.loadingFirst)?_c(VProgressCircular,{staticClass:"mt-5",attrs:{"indeterminate":"","color":"primary","width":2,"size":20}}):_vm._e()],1)],1):_vm._e(),_c('br'),_c(VRow,[_c(VCol,{attrs:{"cols":"6","xl":"6","md":"6","sm":"12"}},[_c(VAutocomplete,{ref:"camp",attrs:{"items":_vm.campaigns,"item-text":"name","item-value":"id","label":_vm.$t('CampaignFilter'),"disabled":_vm.loadingCampaign,"hide-details":""},model:{value:(_vm.campaignId),callback:function ($$v) {_vm.campaignId=$$v},expression:"campaignId"}})],1),_c(VCol,{attrs:{"cols":"1","xl":"1","md":"1","sm":"1"}},[(_vm.loadingCampaign)?_c(VProgressCircular,{staticClass:"mt-5",attrs:{"indeterminate":"","color":"primary","width":2,"size":20}}):_vm._e()],1)],1),_c('br'),_c(VRow,[_c(VCol,{attrs:{"cols":"6","xl":"6","md":"6","sm":"12"}},[_c(VCombobox,{attrs:{"items":_vm.items,"item-text":"providerName","item-value":"providerId","label":_vm.$t('ProviderElements.SelectProvider'),"disabled":_vm.loadingProviders,"multiple":"","hide-selected":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c(VChip,_vm._b({attrs:{"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(_vm._s(item.providerName))]),_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(" $delete ")])],1):_vm._e()]}}]),model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}})],1),_c(VCol,{attrs:{"cols":"1","xl":"1","md":"1","sm":"1"}},[(_vm.loadingProviders)?_c(VProgressCircular,{staticClass:"mt-5",attrs:{"indeterminate":"","color":"primary","width":2,"size":20}}):_vm._e()],1)],1),_c('br'),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.getDataFromDB}},[_vm._v(" "+_vm._s(_vm.$t('BtnSearch'))+" ")])],1)],1),_c('br'),_c('div',[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.getHeaders,"items":_vm.dataFromDb,"item-key":"name","sort-by":_vm.orderBy,"sort-desc":_vm.sortDesc,"search":_vm.search,"loading":_vm.loading,"locale":_vm.$i18n.locale},on:{"update:sortBy":function($event){_vm.orderBy=$event},"update:sort-by":function($event){_vm.orderBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8"},[_c('div',{staticClass:"container"},[_c(VBtn,{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.exportToExcel()}}},[_vm._v(" "+_vm._s(_vm.$t('VtrReportElements.Download'))+" ")])],1)]),_c('div',{staticClass:"col-4"},[_c(VTextField,{staticClass:"mx-4",attrs:{"label":_vm.$t('LblSearch')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:"item.bounce",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.bounce)+"% ")]}}])})],1),_c(VSnackbar,{attrs:{"timeout":_vm.timeout,"color":"error"},model:{value:(_vm.showMessage),callback:function ($$v) {_vm.showMessage=$$v},expression:"showMessage"}},[_vm._v(" "+_vm._s(_vm.messageText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }