<template>
  <div>
    <v-card>
      <v-card-text>
        <DateTimePicker
          @getDateFrom="dateFrom = $event"
          @getDateTo="dateTo = $event"
        ></DateTimePicker>

        <v-row v-if="!$checkRole('ADVERTISERS') && !$checkRole('ADVERTISERS_USERS') && !$checkRole('PROVIDERS')">
          <v-col
            cols="6"
            xl="6"
            md="6"
            sm="12"
          >
            <v-autocomplete
              v-model="advertiserId"
              :items="advertisers"
              item-text="name"
              item-value="id"
              :label="$t('Advertiser')"
              :disabled="loadingFirst"
              clearable
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="1"
            xl="1"
            md="1"
            m="1"
          >
            <v-progress-circular
              v-if="loadingFirst"
              class="mt-5"
              indeterminate
              color="primary"
              :width="2"
              :size="20"
            ></v-progress-circular>
          </v-col>
        </v-row>
        <br>
        <v-row>
          <v-col
            cols="6"
            xl="6"
            md="6"
            sm="12"
          >
            <v-autocomplete
              ref="camp"
              v-model="campaignId"
              :items="campaigns"
              item-text="name"
              item-value="id"
              :label="$t('CampaignFilter')"
              :disabled="loadingCampaign"
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="1"
            xl="1"
            md="1"
            sm="1"
          >
            <v-progress-circular
              v-if="loadingCampaign"
              class="mt-5"
              indeterminate
              color="primary"
              :width="2"
              :size="20"
            ></v-progress-circular>
          </v-col>
        </v-row>
        <br>
        <v-row>
          <v-col
            cols="6"
            xl="6"
            md="6"
            sm="12"
          >
            <v-combobox
              v-model="select"
              :items="items"
              item-text="providerName"
              item-value="providerId"
              :label="$t('ProviderElements.SelectProvider')"
              :disabled="loadingProviders"
              multiple
              hide-selected
            >
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip
                  v-if="item === Object(item)"
                  v-bind="attrs"
                  :input-value="selected"
                  label
                  small
                >
                  <span class="pr-2">{{ item.providerName }}</span>
                  <v-icon
                    small
                    @click="parent.selectItem(item)"
                  >
                    $delete
                  </v-icon>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
          <v-col
            cols="1"
            xl="1"
            md="1"
            sm="1"
          >
            <v-progress-circular
              v-if="loadingProviders"
              class="mt-5"
              indeterminate
              color="primary"
              :width="2"
              :size="20"
            ></v-progress-circular>
          </v-col>
        </v-row>
        <br>

        <v-btn
          color="primary"
          @click="getDataFromDB"
        >
          {{ $t('BtnSearch') }}
        </v-btn>
      </v-card-text>
    </v-card>
    <br>
    <div>
      <v-data-table
        :headers="getHeaders"
        :items="dataFromDb"
        item-key="name"
        class="elevation-1"
        :sort-by.sync="orderBy"
        :sort-desc.sync="sortDesc"
        :search="search"
        :loading="loading"
        :locale="$i18n.locale"
      >
        <template v-slot:top>
          <div class="row">
            <div class="col-8">
              <div class="container">
                <v-btn
                  outlined
                  color="primary"
                  @click="exportToExcel()"
                >
                  {{ $t('VtrReportElements.Download') }}
                </v-btn>
              </div>
            </div>
            <div class="col-4">
              <v-text-field
                v-model="search"
                :label="$t('LblSearch')"
                class="mx-4"
              ></v-text-field>
            </div>
          </div>
        </template>
        <template v-slot:item.bounce="{ item }">
          {{ item.bounce }}%
        </template>
      </v-data-table>
    </div>
    <v-snackbar
      v-model="showMessage"
      :timeout="timeout"
      color="error"
    >
      {{ messageText }}
    </v-snackbar>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import qs from 'qs'
import * as XLSX from 'xlsx-js-style'
import DateTimePicker from '../Components/DateTimePicker.vue'

export default {
  setup() {
    const select = ref()
    const items = []

    return { select, items }
  },
  components: {
    DateTimePicker,
  },
  data() {
    return {
      orderBy: 'providerName',
      sortDesc: false,
      advertisers: [],
      advertiserId: null,
      campaigns: [],
      loadingCampaign: false,
      loadingProviders: false,
      loadingFirst: false,
      showMessage: false,
      costModel: 'cpc',
      messageText: '',
      campaignId: Number(this.$route.params.campaign_id),
      timeout: 5000,
      search: '',
      dataFromDb: [],
      json_fields: {},
      dateFrom: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1,
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
      )
        .toISOString()
        .substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      loading: false,
      headers: [],
      countVisits: 0,
      items: [],
    }
  },
  computed: {
    getHeaders() {
      return this.headers
    },
  },
  watch: {
    '$i18n.locale': function () {
      this.getDataFromDB()
    },
    advertiserId() {
      this.getCampaigns(false)
    },
    campaignId() {
      this.getProviders()
    },
  },
  mounted() {
    this.firstLoad()
  },
  methods: {
    exportToExcel() {
      const wb = XLSX.utils.book_new()
      const rows = []

      const sl = XLSX.utils.json_to_sheet(rows)

      const visitsCol = 'D'
      let ctrCol = 'E'
      let cpmCol = 'F'
      let coastCol = 'G'
      let bounceCol = 'H'
      let depthCol = 'I'
      let durationCol = 'J'
      let marginlenght = 9

      if (!this.$checkAbility('CAN_VIEW_COST') && this.countVisits > 0) {
        bounceCol = 'G'
        depthCol = 'H'
        durationCol = 'I'
        marginlenght = 8
      }
      if (!this.$checkAbility('CAN_VIEW_COST') && !this.countVisits > 0) {
        ctrCol = 'D'
        cpmCol = 'E'
        marginlenght = 4
      }
      if (this.$checkAbility('CAN_VIEW_COST') && !this.countVisits > 0) {
        ctrCol = 'D'
        cpmCol = 'E'
        coastCol = 'F'
        marginlenght = 5
      }

      // Рабочая область
      sl['!ref'] = 'A1:Z50'

      // соединение ячеек
      sl['!merges'] = [{ s: { r: 0, c: marginlenght }, e: { r: 0, c: 0 } }]

      // Ширина столбцов
      sl['!cols'] = [
        { width: 25 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
      ]

      // верхняя шапка
      sl.A1 = {
        t: 's',
        v: `${this.$t('ProvidersExcelHeader')}`,
        s: { fill: { fgColor: { rgb: 'E9E9E9' } }, alignment: { horizontal: 'center' } },
      }

      // шапка таблицы
      sl.A2 = {
        t: 's',
        v: this.$t('ProviderElements.Provider'),
        s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
      }
      sl.B2 = {
        t: 's',
        v: this.$t('ProviderElements.Views'),
        s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
      }
      sl.C2 = {
        t: 's',
        v: this.$t('ProviderElements.Clicks'),
        s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
      }
      if (this.countVisits > 0) {
        sl[`${visitsCol}2`] = {
          t: 's',
          v: this.$t('ProviderElements.Visits'),
          s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
        }
      }
      sl[`${ctrCol}2`] = {
        t: 's',
        v: this.$t('CTR'),
        s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
      }
      sl[`${cpmCol}2`] = {
        t: 's',
        v: this.costModel,
        s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
      }
      if (this.$checkAbility('CAN_VIEW_COST')) {
        sl[`${coastCol}2`] = {
          t: 's',
          v: this.$t('Coast'),
          s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
        }
      }
      if (this.countVisits > 0) {
        sl[`${bounceCol}2`] = {
          t: 's',
          v: this.$t('ProviderElements.Bounce'),
          s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
        }
        sl[`${depthCol}2`] = {
          t: 's',
          v: this.$t('ProviderElements.Depth'),
          s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
        }
        sl[`${durationCol}2`] = {
          t: 's',
          v: this.$t('ProviderElements.Duration'),
          s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
        }
      }

      // Заполняем таблицу через AoA

      let count = 3
      this.dataFromDb.forEach(element => {
        sl[`A${count}`] = {
          t: 's',
          v: element.providerName,
        }
        sl[`B${count}`] = {
          t: 'n',
          v: element.views,
        }
        sl[`C${count}`] = {
          t: 'n',
          v: element.clicks,
        }
        if (this.countVisits > 0) {
          sl[`${visitsCol}${count}`] = {
            t: 'n',
            v: element.visits,
          }
        }
        sl[`${ctrCol}${count}`] = {
          t: 'n',
          v: element.ctr,
        }
        sl[`${cpmCol}${count}`] = {
          t: 'n',
          v: element.costPer,
        }
        if (this.$checkAbility('CAN_VIEW_COST')) {
          sl[`${coastCol}${count}`] = {
            t: 'n',
            v: element.costStat,
          }
        }
        if (this.countVisits > 0) {
          sl[`${bounceCol}${count}`] = {
            t: 'n',
            v: element.bounce,
          }
          sl[`${depthCol}${count}`] = {
            t: 'n',
            v: element.depth,
          }
          sl[`${durationCol}${count}`] = {
            t: 'n',
            v: element.duration,
          }
        }
        // eslint-disable-next-line no-plusplus
        count++
      })

      // Добавляем к документу
      XLSX.utils.book_append_sheet(wb, sl, 'Sheet1')

      XLSX.writeFile(wb, `${this.$t('ProvidersExcelHeader')}.xlsx`)
    },
    firstLoad() {
      this.getAdvertisers().then(() => {
        this.getCampaigns(true).then(() => {
          this.getDataFromDB()
        })
      })
    },

    getAdvertisers() {
      this.loadingFirst = true

      return this.$http
        .get(`${this.$apiBaseURL}/publisher/filter/adv-users`, { params: { userId: this.$getUserId() } })
        .then(response => {
          this.advertisers = response.data

          if (this.firstLoad === true) {
            this.advertiserId = null
          }

          this.loadingFirst = false
        })
    },

    getCampaigns(isFirstLoad) {
      this.loadingCampaign = true

      return this.$http
        .get(`${this.$apiBaseURL}/campaigns/filter/campaigns`, {
          params: { userId: this.$getUserId(), advertiserId: this.advertiserId },
        })
        .then(response => {
          this.campaigns = response.data !== null ? response.data : []
          if (isFirstLoad) {
            if (!this.campaignId) {
              if (this.campaigns.length > 0) {
                this.campaignId = this.campaigns[0].id
              } else {
                this.campaignId = null
              }
            }
          } else if (this.campaigns.length > 0) {
            this.campaignId = this.campaigns[0].id
          } else {
            this.campaignId = null
          }

          this.loadingCampaign = false

          this.getProviders()
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          this.showMessage = true
          this.messageText = error
        })
    },

    getProviders() {
      this.loadingProviders = true
      this.select = null

      const params = {
        userId: this.$getUserId(),
        campaignIds: this.campaignId,
      }

      // this.$http.get('/providers', params)
      this.$http
        .get(
          `${this.$apiBaseURL}/campaigns/filter/traffic-providers?${qs.stringify(params, { arrayFormat: 'repeat' })}`,
        )
        .then(response => {
          this.items = response.data != null ? response.data : []
          this.loadingProviders = false
        })
        .catch(error => {
          console.log(error)
          this.loadingProviders = false
        })
    },

    getDataFromDB() {
      if (!this.campaignId) {
        this.messageText = this.$t('EmptyCampaignId')
        this.showMessage = true

        return
      }

      const currentCampaign = this.campaigns.find(e => e.id === this.campaignId)

      if (currentCampaign) {
        this.costModel = currentCampaign.сost_model_id.toUpperCase()
      }

      this.loading = true

      const dateStart = this.dateFrom != null ? `${this.dateFrom}` : '1970-01-01'
      const dateFinish = this.dateTo != null ? `${this.dateTo}` : `${new Date().toISOString().substr(0, 10)}`
      const providerId = this.select != null ? this.select.map(s => s.providerId) : null

      const params = {
        userId: this.$getUserId(),
        campaignId: this.campaignId,
        dateFrom: dateStart,
        dateTo: dateFinish,
      }

      if (providerId) {
        params.providerIds = providerId
      }

      // this.$http.get(`${Vue.prototype.$apiBaseURL}/providers?` + qs.stringify(params, { arrayFormat: 'repeat' }))
      this.$http
        .get(`${this.$apiBaseURL}/providers?${qs.stringify(params, { arrayFormat: 'repeat' })}`)
        .then(response => {
          this.dataFromDb = response.data != null ? response.data : []
          this.countVisits = 0
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.dataFromDb.length; i++) {
            this.countVisits += this.dataFromDb[i].visits
          }

          this.headers = []

          this.headers.push({ text: this.$t('ProviderElements.Provider'), value: 'providerName' })
          this.headers.push({ text: this.$t('ProviderElements.Views'), value: 'views' })
          this.headers.push({ text: this.$t('ProviderElements.Clicks'), value: 'clicks' })
          if (this.countVisits > 0) {
            this.headers.push({ text: this.$t('ProviderElements.Visits'), value: 'visits' })
          }
          this.headers.push({ text: 'CTR', value: 'ctr' })
          this.headers.push({ text: this.costModel, value: 'costPer' })
          if (this.$checkAbility('CAN_VIEW_COST')) {
            this.headers.push({ text: this.$t('ProviderElements.Cost'), value: 'costStat' })
          }
          if (this.countVisits > 0) {
            this.headers.push({ text: this.$t('ProviderElements.Bounce'), value: 'bounce' })
            this.headers.push({ text: this.$t('ProviderElements.Depth'), value: 'depth' })
            this.headers.push({ text: this.$t('ProviderElements.Duration'), value: 'duration' })
          }

          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.loading = false
        })
    },
  },
}
</script>
